<template>
  <el-row class="error-page" :gutter="20" justify="space-between">
    <el-col class="error-content" :xs="24" :sm="12" :md="10">
      <el-row class="error-info">
        <span class="error-code">
          {{ $route.params && $route.params.code ? $route.params.code : 500 }}
        </span>
        <el-col :span="24"></el-col>
        <span>链接无效，请联系管理员~</span>
        <el-col :span="24"></el-col>
        <span class="error-help">您可以先检查网址，然后重新输入或给我们反馈问题。</span>
      </el-row>
    </el-col>
    <el-col class="error-content" :xs="0" :sm="12" :md="14">
      <img v-if="$route.params && $route.params.code && $route.params.code == 404"
           src="@/assets/images/base/error/404.png">
    </el-col>
  </el-row>

</template>

<script setup>
let width = window.screen.width
if (width < 768) {
  document.querySelector('html').style.minWidth = 'auto'
  document.querySelector('body').style.minWidth = 'auto'
  document.querySelector('#app').style.minWidth = 'auto'
  document.querySelector('#app').firstChild.style.minWidth = 'auto'
}
console.log(width)

</script>

<style scoped>
.error-page {
  width: 100%;
  height: 100%;
  padding: 50px;
}

.error-page .error-content {
  height: 100%;
}

.error-page img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.error-info {
  font-size: 32px;
  color: #333333;
  line-height: 45px;
  width: 390px;
  height: 100%;
  margin: auto;
  align-content: center;
}

.error-code {
  color: #CFDBF5;
  font-size: 80px;
  line-height: 112px;
}

.error-help {
  font-size: 16px;
  color: #333333;
  line-height: 22px;
}

@media screen and (max-width: 728px) {
  .error-info {
    width: 100%;
  }
}

</style>